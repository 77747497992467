<template>
  <div class="footer">
    <div class="footer-content">
      <img
        src="@/assets/images/logo_systemx-en-white.svg"
        style="max-height: 16vh"
      />
      <!-- 
      <div class="coord">
        - MAIL ADDRESS
        <div>IRT SystemX</div>
        <div>Centre d’intégration Nano-INNOV</div>
        <div>8, Avenue de la Vauve – CS 90070</div>
        <div>91127 PALAISEAU CEDEX</div>
      </div>
      <div class="contact">
        - CONTACT INFORMATION
        <div class="left-border-wrapper">
          <a href="mailto:contact@irt-systemx.fr">contact[at]irt-systemx.fr</a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  font-family: "Open Sans", Arial, sans-serif;
}

.footer {
  margin-top: 30px;
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.9rem;
  color: white;
}

.footer a {
  color: #2ea3f2;
  text-decoration: none;
  font-size: 0.9rem;
}

.footer a:hover {
  color: #2ea3f2;
  text-decoration: underline;
}

.footer-content {
  background: #222222;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  min-height: 50px;
  font-size: 0.85rem;
  padding-bottom: 0px;
}

.footer-content img {
  margin-top: 0px;
}

.footer-content > div {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 40px;
}

.footer-content > div:not(:first-child) {
  margin-left: 50px;
}

.footer-content > div > div {
  line-height: 1.4rem;
}

.footer-content > div > ul {
  padding-inline-start: 20px;
}

.footer-content > div > div:first-child {
  margin-top: 15px;
}

.footer-content .left-border-wrapper {
  padding: 2px 2px 2px 15px;
  border-left: 1px solid white;
  margin-bottom: 20px;
}

.footer-content .social-networks {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.footer-content .social-networks > div:hover {
  color: #2ea3f2;
}

.footer-content .social-networks > div i {
  font-size: 1rem;
  margin-right: 30px;
}

.footer-legal {
  background: #000000;
  height: 52px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.footer-legal > div:not(:first-child) {
  margin-left: 10px;
}
</style>