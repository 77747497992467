<template>
  <div class="header">
    <div
      class="menu-container"
      :class="{ 'menu-container-scrolled': !showNavbar }"
    >
      <!-- <div class="logo-container">
        <router-link :to="{ name: 'Home' }">
          <img
            class="systemx-logo-img"
            src="@/assets/images/Logo_SystemX-EN-B_BL_Const_RVB-1000px.png"
        /></router-link>
      </div> -->

      <div class="navigation-container">
        <nav class="main-menu">
          <ul>
            <li
              v-for="(routes, id) in routesList"
              :key="id"
              class="menu-main-item"
            >
              <router-link
                class="router-link-main"
                active-class="main-selected"
                :to="{ name: routes.name }"
                >{{ routes.title }}</router-link
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      title: "test",
      showNavbar: "false",
      maxScroll: 200,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop; // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      } // Here we determine whether we need to show or hide the navbar
      this.showNavbar = currentScrollPosition < this.maxScroll; // Set the current scroll position as the last scroll position
    },
  },
  computed: {
    routesList() {
      return this.$router.options.routes.filter((item) => {
        return !!item.title;
      });
    },
  },
  watch: {
    $route(newValue, oldvalue) {
      this.title = newValue;
      if (this.$matomo) {
        this.$matomo.setReferrerUrl("oldvalue");
        this.$matomo.trackPageView("newValue");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}
.logo-container {
  position: absolute;
  height: 100%;
}

.menu-container {
  z-index: 1000;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  position: fixed;
  top: 0;
  padding-top: 30px;
  height: 80px;
  display: block;
  overflow: hidden;
}

.menu-container-scrolled {
  background-color: #29678a;
}

.systemx-logo-img {
  display: inline-block;
  float: none;
  max-height: 64%;
  margin-bottom: 0;
  vertical-align: middle;
}

.navigation-container {
  float: right;
  padding-right: 100px;
  line-height: 80px;
}
.main-menu {
  float: left;
  display: block;
}

.main-menu > ul {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: 0 0;
  font-size: 100%;
}
.main-menu > ul > li {
  vertical-align: middle;
  display: inline-block;
  word-wrap: break-word;
  position: relative;
  color: #ffffff;
  font-weight: 500;
  line-height: 23px;
  padding-right: 20px;
}

.router-link-main {
  display: block;
  position: relative;
  padding-bottom: 10px !important;
  text-decoration: none;
  color: #ffffff;
}

.main-menu > ul > li:hover > ul {
  animation: fadeLeft 0.4s ease-in-out;
  visibility: visible;
  opacity: 1;
}

.main-selected {
  border-bottom: 3px solid #fff;
}

.active-class {
  color: red;
}

.subMenu {
  position: absolute;
  padding: 0 !important;
  border-top: 3px solid #fff !important;
  margin-top: -3px;
  font-size: 14px;
  width: auto !important;
  visibility: hidden;
  animation: fadeLeft 0.4s ease-in-out;
}

.subMenu > li {
  position: relative;
  display: inline-block;
  background: #29678a;
  text-transform: none;
  width: 100%;
  text-decoration: none;
  word-wrap: break-word;
  padding: 0 !important;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.router-link-sub {
  width: auto !important;
  text-decoration: none;
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 600;
  padding: 15px !important;
  line-height: 2em;
  margin-top: 5px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.subMenu > li:hover {
  background-color: #131720;
}
</style>