<template>
  <div id="app">
    <Header />
    <router-view />

    <footer>
      <Footer-Content />
    </footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

import FooterContent from "@/components/FooterContent";

export default {
  name: "App",
  components: {
    Header,

    FooterContent,
  },
};
</script>

<style lang="scss">
@import "./assets/style/main.css";
</style>